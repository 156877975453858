
import { ExternalLink } from 'lucide-react';
import { useIsMobile } from '@/hooks/use-mobile';
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const PromotionalBanner = ({ className = "" }: { className?: string }) => {
  const isMobile = useIsMobile();

  return (
    <Card className={`relative border-2 border-blue-500 shadow-md overflow-hidden ${className}`}>
      <div className="flex flex-col p-4 pt-6">
        <div className="flex justify-center mb-5">
          <img 
            src="/lovable-uploads/10a9a2ed-a335-4004-861a-34e2372da9b7.png" 
            alt="Väre" 
            className="h-8 w-auto"
            loading="lazy" 
          />
        </div>
        
        <h4 className="text-center font-bold text-blue-800 mb-2">Tarjous: Perusmaksut 0€/6kk</h4>
        
        <p className={`text-sm text-slate-700 mb-4 text-center`}>
          Väre Markkinasähkön perusmaksut nyt 0€ ensimmäisen 6kk ajaksi. Tarjous voimassa 6.4. asti.
        </p>
        
        <div className="mt-auto">
          <a 
            href="https://www.sahkon-kilpailutus.fi/tuote/vare-markkinasahko-n1/?utm_source=porssisahko-fi&utm_medium=partner&utm_campaign=porssisahko-fi-vare-6kk" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-sm bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded flex items-center gap-1.5 transition-colors whitespace-nowrap justify-center shadow-lg w-4/5 mx-auto font-medium"
          >
            Katso lisää
            <ExternalLink className="h-3.5 w-3.5" />
          </a>
        </div>
      </div>
    </Card>
  );
};

export default PromotionalBanner;
